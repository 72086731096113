<template>
  <div :style="{ marginTop: isWxApplets ? '46px' : '' }">
    <template v-if="isWxApplets">
      <van-nav-bar
        title="我的订单"
        fixed
        left-arrow
        @click-left="onClickLeft"
      />
    </template>
    <van-tabs v-model="active" @click="changeStatus">
      <van-tab
        v-for="(item, index) in order_status"
        :title="item.name"
        :name="item.value"
        :key="index"
      >
      </van-tab>
    </van-tabs>
    <div style="height: 10px; background-color: #f5f5f5"></div>
    <van-pull-refresh v-model="isLoading" @refresh="onRefresh">
      <!-- 订单 -->

      <div v-if="active != 4">
        <van-list
          v-model="loading"
          :finished="finished"
          finished-text="没有更多了"
          @load="init"
        >
          <div v-for="(item, i) in orderList" :key="i">
            <div class="order_info">
              <div class="info_num" @click="gotoDetail(item.Id)">
                <span>订单编号: {{ item.ShopOrderNumber }}</span>
                <span class="info_status">{{
                  item.Status == 0
                    ? "待付款"
                    : item.Status == 1
                    ? "待发货"
                    : item.Status == 2
                    ? "待收货"
                    : item.Status == 3
                    ? "交易成功"
                    : item.Status == 4
                    ? "已退款"
                    : "交易成功"
                }}</span>
              </div>
              <div
                class="info_detail"
                v-for="(goods, j) in item.OrderDetailList"
                :key="j"
                @click="gotoDetail(item.Id)"
              >
                <img :src="goods.SpecImg" alt="" />
                <div class="detail_content">
                  <div>
                    {{
                      goods.GoodsName.length > 25
                        ? goods.GoodsName.substr(0, 25) + "..."
                        : goods.GoodsName
                    }}
                  </div>
                  <div>
                    <span>{{ goods.SpecName }}</span
                    ><span>x{{ goods.GoodsCount }}</span>
                  </div>
                  <div>
                    <span v-if="goods.Points > 0">{{ goods.Points }}积分+</span>
                    ￥<span>{{ goods.GoodsPrice }}</span>
                  </div>
                </div>
              </div>
              <div class="info_price">
                <div>共计{{ item.GoodsCount }}套</div>
                <div>
                  合计：<span>￥</span><span>{{ item.TotalMoney }}</span>
                </div>
              </div>
              <div class="info_btn">
                <div @click="gotoLogistics(item.Id)" v-if="item.Status == 2">
                  查看物流
                </div>
                <div @click="deleteOrder(item.Id)" v-if="item.Status == 0">
                  取消订单
                </div>
                <div
                  v-if="item.Status == 0 || item.Status == 2"
                  class="info_pay"
                  @click="pay(item)"
                >
                  {{ item.Status == 0 ? "去支付" : "确认收货" }}
                </div>
              </div>
            </div>
            <div style="height: 10px; background-color: #f5f5f5"></div>
          </div>
        </van-list>
        <div v-if="orderList.length <= 0">
          <nocontent name="暂无订单"></nocontent>
        </div>
      </div>

      <!-- 订单 -->
      <div v-if="active == 4">
        <van-list
          v-model="loading"
          :finished="finished"
          finished-text="没有更多了"
          @load="init"
        >
          <div v-for="(goods, j) in orderList" :key="j">
            <div class="order_info">
              <div class="info_detail" style="padding-top: 10px">
                <img :src="goods.SpecImg" alt="" />
                <div class="detail_content">
                  <div style="width: 50vw">
                    {{
                      goods.GoodsName.length > 25
                        ? goods.GoodsName.substr(0, 25) + "..."
                        : goods.GoodsName
                    }}
                  </div>
                  <div>
                    <span class="decimalPoint" style="width: 50vw">{{
                      goods.SpecName
                    }}</span>
                    <span>x{{ goods.GoodsCount }}</span>
                  </div>
                  <div>
                    <span v-if="goods.Points > 0">{{ goods.Points }}积分+</span>
                    ￥<span>{{ goods.GoodsPrice }}</span>
                  </div>
                  <div
                    class="info_evaluate"
                    @click="gotoEvaluate(goods.ShopOrderId, goods.SpecId)"
                  >
                    <div class="pj">评价</div>
                  </div>
                </div>
              </div>
            </div>
            <div style="height: 10px; background-color: #f5f5f5"></div>
          </div>
        </van-list>
        <div v-if="orderList.length <= 0">
          <nocontent name="暂无订单"></nocontent>
        </div>
      </div>
    </van-pull-refresh>
    <van-popup v-model="showPicker" round position="bottom">
      <van-picker
        title="支付方式"
        show-toolbar
        :columns="columns"
        @confirm="onConfirm"
        @cancel="onCancel"
      />
    </van-popup>
  </div>
</template>

<script>
import { PullRefreshm, Toast, Dialog } from "vant";
import {
  queryShopOrderList,
  queryEvaluationList,
  shopOrderPay,
  shopOrderDelete,
} from "@/api/shop";
import nocontent from "@/views/shop/components/nocontent";
export default {
  components: {
    nocontent,
  },
  data() {
    return {
      order_status: [
        {
          name: "全部",
          value: "0",
        },
        {
          name: "待付款",
          value: "1",
        },
        {
          name: "待发货",
          value: "2",
        },
        {
          name: "待收货",
          value: "3",
        },
        {
          name: "待评价",
          value: "4",
        },
      ],
      active: 0,
      isLoad: false,
      isLoading: false,
      isEnd: false,
      pageIndex: 1,
      pageSize: 10,
      loading: false,
      finished: false,
      orderList: [],
      userInfo: {},
      evaluationList: [],
      payOrderId: 0,
      showPicker: false,
      columns: ["普利点", "支付宝", "微信"],
      isWxApplets: true,
    };
  },
  created() {
    //判断是否是微信浏览器的函数
    var ua = window.navigator.userAgent.toLowerCase();
    if (ua.match(/MicroMessenger/i) == "micromessenger") {
      document.title = "我的订单";
      this.isWxApplets = false;
    }
    this.active = this.$route.query.activeStatus
      ? this.$route.query.activeStatus
      : 0;
    console.log(this.order_status[this.active], "");
  },
  mounted() {
    if (localStorage.getItem("userInfo") != null) {
      this.userInfo = JSON.parse(localStorage.getItem("userInfo"));
    } else {
      this.userInfo.Id = 0;
    }
    // window.addEventListener("scroll", this.listenScroll);
  },
  methods: {
    init() {
      if (this.active == 4) {
        this.postQueryEvaluationList();
      } else {
        this.postQueryShopOrderList();
      }
    },
    //取消订单
    deleteOrder(id) {
      Dialog.confirm({
        title: "取消订单",
        message: "确定取消该订单吗",
      })
        .then(() => {
          this.postShopOrderDelete(id);
        })
        .catch(() => {
          // on cancel
        });
    },
    //取消订单
    async postShopOrderDelete(id) {
      const res = await shopOrderDelete(id);
      if (res.success == true) {
        Toast.success({
          message: res.msg,
        });
        this.pageIndex = 1;
        this.orderList = [];
        this.isEnd = false;
        this.init();
      } else {
        Toast.fail({
          message: res.msg,
        });
      }
    },
    async onConfirm(value, index) {
      // Toast(`当前值：${value}, 当前索引：${index}`);
      let parm = {
        OrderId: this.payOrderId,
        PayMethod: index,
        OrderSource: "H5",
      };
      const res = await shopOrderPay(parm);
      this.showPicker = false;
      if (res.success == true) {
        if (index == 0) {
          //普利点支付
          Toast.success({
            message: res.msg,
          });
          this.$router.push({
            path: "/shop/orders/detail",
            query: { orderId: res.response.Id },
          });
        } else if (index == 1) {
          //支付宝支付
          const div = document.createElement("div");
          div.id = "alipay";
          div.innerHTML = res.response.PayData;
          document.body.appendChild(div);
          document.querySelector("#alipay").children[0].submit(); // 执行后会唤起支付宝
          this.$router.push({
            path: "/shop/orders/detail",
            query: { orderId: res.response.Id },
          });
        } else if (index == 2) {
          //微信支付
          window.open(res.response.PayData);
          this.$router.push({
            path: "/shop/orders/detail",
            query: { orderId: res.response.Id },
          });
        }
      } else {
        Toast.fail({
          message: res.msg,
        });
      }
    },
    onCancel() {
      Toast("取消支付");
      this.showPicker = false;
    },
    onRefresh() {
      this.orderList = [];
      this.pageIndex = 1;
      this.isEnd = false;
      this.finished = false;
      this.init();
      setTimeout(() => {
        this.isLoading = false;
      }, 1000);
      // this.isLoading = false;
    },
    //前去详情
    gotoDetail(orderId) {
      this.$router.push({
        path: "/shop/orders/detail",
        query: { orderId: orderId },
      });
    },
    //查看物流
    gotoLogistics(orderId) {
      this.$router.push({
        path: "/shop/orders/logistics",
        query: { orderId: orderId },
      });
    },
    // 顶部返回
    onClickLeft() {
      window.history.go(-1);
    },
    gotoEvaluate(orderId, specId) {
      this.$router.push({
        path: "/shop/orders/evaluate",
        query: { orderId: orderId, specId: specId },
      });
    },
    changeStatus() {
      // console.log(this.active);
      this.orderList = [];
      this.pageIndex = 1;
      this.isEnd = false;
      this.init();
    },
    // 列表数据
    async postQueryShopOrderList() {
      let parm = {};
      parm.pageIndex = this.pageIndex;
      parm.pageSize = this.pageSize;
      parm.UserId = this.userInfo.Id;
      parm.Status =
        this.active == 0 ? "" : this.order_status[this.active - 1].value;
      const res = await queryShopOrderList(parm);
      this.orderList = this.orderList.concat(res.response.data);
      this.pageIndex++;
      // 加载状态结束
      this.loading = false;
      // 数据全部加载完成
      if (this.orderList.length >= res.response.dataCount) {
        this.finished = true;
      }
      // console.log(this.orderList, 5465465465);
      // this.isLoad = false;
      // if (res.response.data.length == 0) {
      //   this.isEnd = true;
      // }
    },
    // 待评价列表数据
    async postQueryEvaluationList() {
      let parm = {};
      parm.pageIndex = this.pageIndex;
      parm.pageSize = this.pageSize;
      parm.UserId = this.userInfo.Id;
      parm.Status = 3;
      parm.IsEvaluation = 0;
      const res = await queryEvaluationList(parm);
      this.orderList = this.orderList.concat(res.response.data);
      // console.log(this.orderList);
      this.pageIndex++;
      // 加载状态结束
      this.loading = false;
      // 数据全部加载完成
      if (this.orderList.length >= res.response.dataCount) {
        this.finished = true;
      }
      // this.isLoad = false;
      // if (res.response.data.length == 0) {
      //   this.isEnd = true;
      // }
    },
    //滚动

    listenScroll() {
      let that = this;
      let ele = document.documentElement;
      let scr = ele.scrollTop; // 向上滚动的那一部分高度
      let clientHeight = ele.clientHeight; // 屏幕高度也就是当前设备静态下你所看到的视觉高度
      let scrHeight = ele.scrollHeight; // 整个网页的实际高度，兼容Pc端
      if (scr + clientHeight + 5 >= scrHeight) {
        console.log(that.isEnd);
        // if (that.isLoad == false||this.isEnd == false) {
        if (that.isEnd == false) {
          // that.isLoad = true;
          that.pageIndex++;
          this.init();
        }
      }
    },
    // 付款
    pay(itm) {
      if (itm.Status == 0) {
        // console.log("付款");
        this.showPicker = true;
        this.payOrderId = itm.Id;
      } else {
        this.$router.push({
          path: "/shop/orders/logistics",
          query: { orderId: itm.Id },
        });
      }
    },
  },
  beforeDestroy() {
    // window.removeEventListener('scroll', this.listenScroll);
  },
};
</script>

<style lang="scss" scoped>
/deep/ .van-ellipsis {
  color: #333333;
  font-size: 1rem;
  font-weight: 700;
}
/deep/ .van-nav-bar .van-icon {
  color: #333333;
}
//导航栏下边框
/deep/ .van-hairline--bottom::after {
  border: 0;
}
.order_info {
  background: #fff;
  width: 100%;
  // height: 15.375rem;
  box-sizing: border-box;
  padding: 0 0.9375rem;
  padding-bottom: 10px;
  margin-top: 0.625rem;
  .info_num {
    width: 100%;
    height: 2.9375rem;
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-bottom: 1px solid #f5f5f5;
    margin-bottom: 0.75rem;
    .info_status {
      color: #fc7134;
    }
  }

  .info_detail {
    display: flex;
    margin-bottom: 0.9375rem;
    img {
      width: 5.5625rem;
      height: 5.5rem;
      border-radius: 0.25rem;
      margin-right: 0.9375rem;
    }
    .detail_content {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      div:first-child {
        font-size: 0.875rem;
        color: #333333;
        font-weight: 700;
        margin-right: 1.25rem;
      }
      div:nth-child(2) {
        font-size: 0.75rem;
        color: #858585;
        display: flex;
        justify-content: space-between;
      }
      div:nth-child(3) {
        font-size: 0.75rem;
        color: #fe4a4a;
        span {
          color: #fe4a4a;
          font-size: 0.9375rem;
          font-weight: 700;
        }
      }
    }
  }
  .info_price {
    display: flex;
    justify-content: flex-end;
    font-size: 0.875rem;
    color: #999999;
    margin-bottom: 0.875rem;
    div:first-child {
      margin-right: 1.25rem;
    }
    div:nth-child(2) {
      color: #333;
      span:nth-child(1) {
        font-size: 9px;
      }
      span:nth-child(2) {
        font-weight: 700;
      }
    }
  }
  .info_btn {
    display: flex;
    justify-content: flex-end;
    div {
      width: 5rem;
      height: 2.125rem;
      border-radius: 1.875rem;
      line-height: 2.125rem;
      text-align: center;
      font-size: 0.875rem;
      color: #333;
      border: 1px solid #e8e8e8;
    }
    .info_pay {
      background: linear-gradient(to right, #fc7134, #f54024);
      color: #fff;
      margin-left: 0.625rem;
      border: 0;
    }
  }
}
.info_evaluate {
  position: absolute;
  // top: 31%;
  // margin-top: 17%;
  left: 83%;
  width: 3.125rem;
  height: 1.5rem;
  border-radius: 1rem;
  border: 1px solid #e8e8e8;
  color: #999;
  line-height: 1.5rem;
  .pj {
    text-align: center;
    font-weight: 400 !important;
    font-size: 12px !important;
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    margin-right: 0 !important;
  }
}
/deep/ .van-nav-bar .van-icon {
  color: #000;
  font-size: 20px;
}
</style>
